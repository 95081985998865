<template>
	<div id="container" class="container">
		<div id="sub" class="sub map">
			<!-- [D] 지역 선택 -->
			<div style="display:block" class="layer">
				<div class="layer__full layer__map">
					<div class="layer__fullbox">
						<div class="layer__fullheader">
							<nav class="nav nav--fit">
								<div class="nav__wrap">
									<a class="nav__btn" @click="handleClose()">
										<i class="icon icon-close"></i>
									</a>
									<p class="nav__item">
										<span class="nav__text">
											숙소 표시
										</span>
									</p>
									<a class="nav__btn">
									</a>
								</div>
							</nav>
						</div>
						<div class="layer__fullbody" style="height: 100%">
							<div class="map__wrap" style="height: 100%">
                                <div id="map" class="map__box" style="width: 100%" />
                            </div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { onMounted, ref, watchEffect } from 'vue'
import { onBeforeRouteLeave } from 'vue-router';
export default {
	props: ['handleClose', 'latitude', 'longitude'], 
    setup(props) {
        const map = ref(null);
        const marker = ref(null);

        watchEffect(() => {
            if(map.value && marker.value){
                map.value.panTo(new window.kakao.maps.LatLng(props.latitude, props.longitude));
                marker.value.setPosition(new window.kakao.maps.LatLng(props.latitude, props.longitude));
            }
        })
        onMounted(() => {
			
            //console.log("22")
            const container = document.getElementById('map'); //지도를 담을 영역의 DOM 레퍼런스
            const options = { //지도를 생성할 때 필요한 기본 옵션
                center: new window.kakao.maps.LatLng(props.latitude, props.longitude), //지도의 중심좌표.
                level: 3 //지도의 레벨(확대, 축소 정도)
            };
            map.value = new window.kakao.maps.Map(container, options);
            marker.value = new window.kakao.maps.Marker({
                map: map.value,
                position: new window.kakao.maps.LatLng(props.latitude, props.longitude), // 마커를 표시할 위치
            })
        })
		onBeforeRouteLeave(() => {
			props.handleClose();
			return false;
		})
    },
}
</script>