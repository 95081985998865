<template>
	<nav class="nav">
		<div class="nav__wrap">
			<a @click="goBack" class="nav__btn nav__btn--prev">
				<i class="icon icon-left">{{t('10031')}}</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					{{t('10259')}}
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<div id="container" class="container container--fit">
		<div id="sub" class="sub mypage">
			<section class="content">
				<div class="box">
					<!-- tab -->
					<div class="tab">
						<a :class="listTab === 0 && 'active'" @click="handleChangeTab(0)">{{t('10260')}}</a>
						<a :class="listTab === 1 && 'active'" @click="handleChangeTab(1)">{{t('10261')}}</a>
						<a :class="listTab === 2 && 'active'" @click="handleChangeTab(2)">{{t('10262')}}</a>
					</div>
					<!-- 예약 내역-->
					<div class="reservation">
						<!-- 예약 아이템 -->
						<div class="reservation-item" v-for="reservation in currentData" :key="reservation.BOOKING_NO + reservation.SEQ">
							<!-- [D] 예약 상태에 따라 클래스 변경 ing, end, cancel-->
							<div class="reservation__status" :class="listTab === 0 ? 'ing': listTab === 1 ? 'end' : 'cancel'">
								<strong>{{reservation.BOOKING_DATETIME}}</strong>
								<span class="reservation__badge">
									{{listTab === 0 ? `D - ${reservation.dday}` : listTab === 1 ? '이용완료' : '취소완료' }}
								</span>
							</div>
							<div class="reservation__number">
								<div class="flex">
									<span>
										{{t('10264')}} {{reservation.BOOKING_NO}}
									</span>
									<router-link :to="'/order/detail/' + reservation.BOOKING_NO + '?seq=' + reservation.SEQ" v-if="listTab === 1">
										{{t('10265')}}
									</router-link>
									<router-link :to="'/order/cancel/' + reservation.BOOKING_NO + '?seq=' + reservation.SEQ" v-else-if="listTab === 2">
										{{t('10265')}}
									</router-link>
								</div>
							</div>
							<div class="reservation__info">
								<h3 class="reservation__title">{{reservation.HOTEL_NM}}</h3>
								<div class="flex">
									<div class="top__type">
										<span class="top__badge grey1"> {{reservation.PROD_TYPE}} </span>
										{{reservation.PROD_NM}} <!-- 방 타입 -->
									</div>
									<div class="detail__sns">
										<button type="button" @click="handleOpenMap(reservation.LATITUDE, reservation.LOGITUDE)">
											<i class="icon icon-location--small">{{t('10268')}}</i>
											{{t('10268')}}
										</button>
										<a :href="'tel:' + reservation.TEL_NO">
											<i class="icon icon-tel"></i> {{t('10269')}}
										</a>
									</div>
								</div>
								<ul class="reservation__time">
									<li><span>{{t('10279')}}</span> {{reservation.checkin}}</li>
									<li><span>{{t('10280')}}</span> {{reservation.checkout}}</li>
								</ul>
							</div>
							<div class="reservation__button" v-if="listTab === 0">
								<div class="btns">
									<router-link :to="'/mypage/booking/cancel/' + reservation.BOOKING_NO + '?seq=' + reservation.SEQ" custom v-slot="{ navigate }">
										<button type="button" class="btn btn-primary normal" @click="navigate">
											{{t('10276')}}
										</button>
									</router-link>
									<router-link :to="'/order/detail/' + reservation.BOOKING_NO + '?seq=' + reservation.SEQ" custom v-slot="{ navigate }">
										<button type="button" class="btn btn-primary normal" @click="navigate">
											{{t('10265')}}
										</button>
									</router-link>
								</div>
							</div>
							<div class="reservation__button" v-else-if="listTab === 1">
								<div class="btns">
									<router-link :to="'/rooms/' + reservation.HOTEL_NO" custom v-slot="{ navigate }">
										<button type="button" class="btn btn-primary normal" @click="navigate">
											{{t('10273')}}
										</button>
									</router-link>
									<button type="button" class="btn btn-primary normal" v-if="reservation.dday >= 0" @click="writeReview(reservation)" :disabled="reservation.REVIEW_CNT > 0">
										<!-- [D] 리뷰 작성 가능 기간 표시 -->
										<span class="btn-badge" v-if="reservation.REVIEW_CNT === 0 || reservation.REVIEW_CNT === undefined">
											D-{{reservation.dday}}
										</span>
										<!-- // -->
										{{reservation.REVIEW_CNT > 0 ? "리뷰 작성 완료" : t('10274')}}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row" v-show="currentData.length <= 0">
					<div class="centered centered--xlarge">
						<div class="centered__wrap">
							<div class="textbox">
								<div class="textbox__icon">
									<img src="@/assets/images/img_warning.png" alt="찾으시는 결과가 없나요?" style="width:64px">
								</div>
								<h3 class="textbox__title">{{listTab == 0 ? '예약': listTab === 1 ? '이용' : '취소'}}{{t('10275')}}</h3>
								<p class="textbox__description" v-if="listTab !== 2">
									{{t('10378')}}
								</p>
								<div class="textbox__button" v-if="listTab !== 2">
									<router-link to="/rooms">
										<button type="button" class="btn btn-primary purple">
											{{t('10028')}}
										</button>
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
	<Room :handleClose="handleCloseMap" :latitude="latitude" :longitude="longitude" v-if="openMap" />
	<TheNavigation></TheNavigation>
</template>

<script>
import { computed, onMounted, ref, watchEffect } from '@vue/runtime-core'
import { useStore } from 'vuex'
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { useRoute, useRouter } from 'vue-router';
import Room from "@/components/layers/map/Room";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

dayjs.locale("ko");

export default {
	components: {
		Room
	},
	setup() {
		const store = useStore();
		const router = useRouter();
		const route = useRoute();
		const { t }= useI18n() //번역필수 모듈
		const userData = computed(() => store.state.userData);
		const mmy0201 = computed(() => store.state.mmy02.mmy0201);
		const mmy020102 = computed(() => store.state.mmy02.mmy020102);
		const mmy020109 = computed(() => store.state.mmy02.mmy020109);

		const currentData = computed(() =>{
				if(listTab.value === 0){
					return mmy0201.value.filter(item => item.CANCEL_YN === "N").
					map(reservation => (
					{
						...reservation, 
						BOOKING_DATETIME: dayjs(reservation.BOOKING_DATETIME).format("YYYY.MM.DD (dd)"),
						dday: dayjs(reservation.SDATE).diff(dayjs().startOf('day'), "day"),
						checkin: dayjs(reservation.SDATE).format("YYYY.MM.DD (dd)") + " " + reservation.CHECKIN_TIME,
						checkout: dayjs(reservation.EDATE).format("YYYY.MM.DD (dd)") + " " + reservation.CHECKOUT_TIME,
					}));
				}
				if(listTab.value === 1){
					return mmy020102.value.map(reservation => (
					{
						...reservation, 
						BOOKING_DATETIME: dayjs(reservation.BOOKING_DATETIME).format("YYYY.MM.DD (dd)"),
						dday: dayjs(reservation.SDATE).add(30, 'day').diff(dayjs().startOf('day'), "day"),
						checkin: dayjs(reservation.SDATE).format("YYYY.MM.DD (dd)") + " " + reservation.CHECKIN_TIME,
						checkout: dayjs(reservation.EDATE).format("YYYY.MM.DD (dd)") + " " + reservation.CHECKOUT_TIME,
					}));
				}
				if(listTab.value === 2){
					return mmy020109.value.map(reservation => (
					{
						...reservation, 
						BOOKING_DATETIME: dayjs(reservation.BOOKING_DATETIME).format("YYYY.MM.DD (dd)"),
						dday: dayjs(reservation.SDATE).diff(dayjs().startOf('day'), "day"),
						checkin: dayjs(reservation.SDATE).format("YYYY.MM.DD (dd)") + " " + reservation.CHECKIN_TIME,
						checkout: dayjs(reservation.EDATE).format("YYYY.MM.DD (dd)") + " " + reservation.CHECKOUT_TIME,
					}));
				}
		});
		
		const openMap = ref(false);
		const latitude = ref(0);
		const longitude = ref(0);
		const listTab = ref(0);
		
		watchEffect(() => {
			if(route.query.id) {
				listTab.value = Number(route.query.id);
			}
		})

		const goBack = () => {
			router.back();
		}

		onMounted(() => {
			store.dispatch("mmy02/fetchMmy0201", { proc_cd: "01", mem_id: userData.value.mem_token,});
			store.dispatch("mmy02/fetchMmy020102", { proc_cd: "02", mem_id: userData.value.mem_token,});
			store.dispatch("mmy02/fetchMmy020109", { mem_id: userData.value.mem_token,});
		});

		return {
			currentData,
			goBack,
			t,  //번역필수 모듈
		  i18n,
			openMap,
			latitude,
			longitude,
			handleOpenMap: (lat, long) => {
				latitude.value = lat;
				longitude.value = long;
				openMap.value = true;
			},
			handleCloseMap: () => {
				openMap.value = false;
			},
			listTab,
			handleChangeTab: (index) => {
				listTab.value = index;
			},
			writeReview: (reservation) => {
				router.push(`/mypage/myreview/write?hotel_no=${reservation.HOTEL_NO}&prod_no=${reservation.PROD_NO}&booking_no=${reservation.BOOKING_NO}&hotel_nm=${reservation.HOTEL_NM}&prod_nm=${reservation.PROD_NM}&prod_type=${reservation.PROD_TYPE}`);
			}
		}
	},
}
</script>